import styles from 'styled-components';

export const Container = styles.div`
`;

export const Title = styles.h2`
font-family: Passion One;
  font-size: 36px;
  margin: .5em 0;
`;
